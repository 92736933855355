import React, { useState, useEffect, useContext, useRef } from 'react';
import { ArrowLeftIcon, AtSymbolIcon, LockClosedIcon, UserIcon, CalendarIcon } from '@heroicons/react/outline';
import { register, login, requestPasswordReset, checkEmailExists, resendVerificationEmail } from '../../services/authService';
import { useNavigate, useLocation } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { AuthContext } from '../../context/AuthContext';

const LoginSignup = () => {
    useDocumentTitle('Account');

    const [formStatus, setFormStatus] = useState({
        email: '',
        password: '',
        displayName: '',
        dateOfBirth: '',
        agreeToTerms: false,
        subscribeToNewsletter: false,
        loading: false,
        showResetPrompt: false,
        error: '',
        success: '',
        stage: 'email',
        signupCompleted: false,
        isEmailBlacklisted: false,
    });

    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const location = useLocation();
    const [logoutMessage, setLogoutMessage] = useState('');
    const { setTokenExpiry, verifyAuth } = useContext(AuthContext);
    const passwordInputRef = useRef(null);

    useEffect(() => {
        const handler = setTimeout(() => {
            setFormStatus(prev => ({ ...prev, email: formStatus.email }));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [formStatus.email]);

    const handleEmailCheck = async () => {
        if (!formStatus.email) return;
        setLogoutMessage('');
        setFormStatus(prev => ({ ...prev, loading: true, error: '', showResetPrompt: false }));
        try {
            const { exists } = await checkEmailExists(formStatus.email);
            setFormStatus(prev => ({
                ...prev,
                stage: exists ? 'login' : 'signup',
                loading: false,
                error: exists ? '' : "Looks like you're new here! Sign up to get started!"
            }));
        } catch (error) {
            setFormStatus(prev => ({ ...prev, loading: false, error: error.message }));
        }
    };

    useEffect(() => {
        setLogoutMessage('');
    }, []);

    useEffect(() => {
        if (location.state?.loggedOut) {
            setLogoutMessage("You have successfully logged out.");
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    const handleLogin = async () => {
        setFormStatus(prev => ({ ...prev, loading: true, error: '', success: '', unverifiedEmail: false }));
        try {
            const response = await login({
                email: formStatus.email,
                password: formStatus.password,
                rememberMe: rememberMe
            });
    
            if (response && response.tokenExpiry) {
                setTokenExpiry(response.tokenExpiry);
            }
            await verifyAuth();
            navigate('/dashboard', { replace: true });
        } catch (error) {
            let errorMessage = 'Login failed. Please try again.';
            let unverified = false;
    
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message || errorMessage;
                unverified = error.response.data.unverified || false;
            } else if (error.message) {
                errorMessage = error.message;
            }
    
            if (errorMessage.includes('not verified') && !errorMessage.includes('or')) {
                errorMessage += ' or ';
                unverified = true;
            }
    
            setFormStatus(prev => ({
                ...prev,
                loading: false,
                error: errorMessage,
                unverifiedEmail: unverified
            }));
        }
    };
    
    const handleSignup = async () => {
        setFormStatus(prev => ({ ...prev, loading: true, error: '' }));
        const userData = {
            email: formStatus.email,
            password: formStatus.password,
            display_name: formStatus.displayName,
            date_of_birth: formStatus.dateOfBirth,
            email_verified: false,
            email_subscribed: formStatus.subscribeToNewsletter ? 1 : 0
        };

        try {
            await register(userData);
            setFormStatus(prev => ({
                ...prev,
                loading: false,
                success: `Account created successfully! Please verify your account by following the link sent to ${formStatus.email}.`,
                error: '',
                signupCompleted: true
            }));
        } catch (error) {
            if (error.message === 'Registration is currently disabled.') {
                setFormStatus(prev => ({
                    ...prev,
                    loading: false,
                    error: 'Registration is currently not available. Please check back later.'
                }));
            } else {
                setFormStatus(prev => ({
                    ...prev,
                    loading: false,
                    error: error.message || 'An unexpected error occurred during registration. Please try again later.'
                }));
            }
        }
    };

    const handleResendVerification = async () => {
        setFormStatus(prev => ({ ...prev, loading: true, error: '', success: '' }));
        try {
            await resendVerificationEmail(formStatus.email);
            setFormStatus(prev => ({ ...prev, loading: false, success: 'Verification email resent. Please check your email.' }));
        } catch (error) {
            const customErrorMessage = error.response?.data?.message || error.message || 'An error occurred while resending the verification email. Please try again later.';
            const emailBlacklisted = customErrorMessage.includes('Oops! Your email address is invalid or has been blacklisted');
            setFormStatus(prev => ({
                ...prev,
                loading: false,
                error: customErrorMessage,
                isEmailBlacklisted: emailBlacklisted,
            }));
        }
    };  

    const handleForgotPassword = () => {
        if (!formStatus.email) {
            setFormStatus(prev => ({ ...prev, error: "Please enter your email to reset your password." }));
            return;
        }
        setFormStatus(prev => ({ ...prev, showResetPrompt: true, error: '', success: '' }));
    };

    const sendResetEmail = async () => {
        setFormStatus(prev => ({ ...prev, showResetPrompt: false, loading: true }));
        try {
            await requestPasswordReset(formStatus.email);
            setFormStatus(prev => ({
                ...prev,
                loading: false,
                success: `Password reset email has been sent to ${formStatus.email}. Please check your email.`,
                error: ''
            }));
        } catch (error) {
            setFormStatus(prev => ({
                ...prev,
                loading: false,
                error: error.message || 'An error occurred while sending the password reset email. Please try again later.'
            }));
        }
    };

    const handleBackButtonClick = () => {
        setFormStatus(prev => ({ ...prev, stage: 'email', error: '', success: '' }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (formStatus.stage) {
            case 'email':
                handleEmailCheck();
                break;
            case 'login':
                handleLogin();
                break;
            case 'signup':
                handleSignup();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (formStatus.stage === 'login') {
            passwordInputRef.current?.focus();
        }
    }, [formStatus.stage]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex items-center justify-center">
                        {formStatus.stage !== 'email' && (
                            <button
                                onClick={handleBackButtonClick}
                                className="p-2 -ml-2 mr-4 text-gray-600 hover:text-gray-800"
                            >
                                <ArrowLeftIcon className="w-5 h-5" />
                            </button>
                        )}
                        {/* Heading */}
                        <h2 className="text-lg font-semibold text-center flex-grow">
                            {formStatus.stage === 'email' && 'Enter your email address'}
                            {formStatus.stage === 'login' && !formStatus.isResettingPassword && 'Welcome back!'}
                            {formStatus.stage === 'signup' && 'Sign Up'}
                            {formStatus.isResettingPassword && 'Check your email inbox!'}
                            {formStatus.stage === 'promptSignup' && 'Welcome to Powafull!'}
                        </h2>
                    </div>

                    {/* Email Field */}
                    <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500">
                        <AtSymbolIcon className="w-6 h-6 m-2 text-gray-500" />
                        <input
                            type="email"
                            placeholder="Email"
                            className={`w-full px-4 py-2 focus:outline-none ${formStatus.stage !== 'email' ? 'bg-gray-200 text-gray-600 cursor-not-allowed' : ''}`}
                            value={formStatus.email}
                            onChange={(e) => setFormStatus(prev => ({ ...prev, email: e.target.value }))}
                            required
                            readOnly={formStatus.stage !== 'email'}
                        />
                    </div>

                    {/* Password Field */}
                    {formStatus.stage === 'login' && (
                        <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500">
                            <LockClosedIcon className="w-6 h-6 m-2 text-gray-500" />
                            <input
                                type="password"
                                onKeyDown={e => { if (e.key === 'Enter') handleLogin(); }}
                                placeholder="Password"
                                className="w-full px-4 py-2 focus:outline-none"
                                value={formStatus.password}
                                onChange={(e) => setFormStatus(prev => ({ ...prev, password: e.target.value }))}
                                required
                            />

                            {/* Remember Me Checkbox */}
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                                <span className="text-sm text-gray-700">Remember me?</span>
                            </label>
                        </div>
                    )}

                    {/* Prompt for Signup */}
                    {formStatus.stage === 'promptSignup' && (
                        <div className="text-center">
                            <p className="mb-4">{formStatus.error}</p>
                            <button
                                onClick={() => setFormStatus(prev => ({ ...prev, stage: 'signup' }))}
                                className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                                Proceed to Sign Up
                            </button>
                        </div>
                    )}

                    {/* Signup Fields */}
                    {formStatus.stage === 'signup' && (
                        <>
                            {/* Password Field for Signup */}
                            <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500 mt-4">
                                <LockClosedIcon className="w-6 h-6 m-2 text-gray-500" />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    className="w-full px-4 py-2 focus:outline-none"
                                    value={formStatus.password}
                                    onChange={(e) => setFormStatus(prev => ({ ...prev, password: e.target.value }))}
                                    required
                                />
                            </div>

                            {/* Display Name Field */}
                            <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500 mt-4">
                                <UserIcon className="w-6 h-6 m-2 text-gray-500" />
                                <input
                                    type="text"
                                    placeholder="Display Name"
                                    className="w-full px-4 py-2 focus:outline-none"
                                    value={formStatus.displayName}
                                    onChange={(e) => setFormStatus(prev => ({ ...prev, displayName: e.target.value }))}
                                    required
                                />
                            </div>

                            {/* Date of Birth Field */}
                            <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500 mt-4">
                                <CalendarIcon className="w-6 h-6 m-2 text-gray-500" />
                                <input
                                    type="date"
                                    className="w-full px-4 py-2 focus:outline-none"
                                    value={formStatus.dateOfBirth}
                                    onChange={(e) => setFormStatus(prev => ({ ...prev, dateOfBirth: e.target.value }))}
                                    required
                                />
                            </div>

                            {/* Checkbox Fields */}
                            <div className="mt-4">
                                <label className="flex items-center space-x-3">
                                    <input
                                        type="checkbox"
                                        checked={formStatus.agreeToTerms}
                                        onChange={(e) => setFormStatus(prev => ({ ...prev, agreeToTerms: e.target.checked }))}
                                        required
                                    />
                                    <span>Agree to Terms and Conditions</span>
                                </label>
                                <label className="flex items-center space-x-3 mt-2">
                                    <input
                                        type="checkbox"
                                        checked={formStatus.subscribeToNewsletter}
                                        onChange={(e) => setFormStatus(prev => ({ ...prev, subscribeToNewsletter: e.target.checked }))}
                                    />
                                    <span>Subscribe to newsletter</span>
                                </label>
                            </div>
                        </>
                    )}

                    {/* Forgotten Password Button */}
                    {formStatus.stage === 'login' && (
                        <div className="flex justify-between items-center mt-4">
                            <button type="button" onClick={handleForgotPassword} className="text-blue-500 hover:text-blue-600 text-sm">
                                Forgotten password?
                            </button>
                        </div>
                    )}

                    {/* Reset Email Prompt */}
                    {formStatus.stage === 'login' && formStatus.showResetPrompt && (
                        <p className="text-center text-sm mt-4">
                            This will trigger a password reset email sent to {formStatus.email} -{' '}
                            <button
                                onClick={sendResetEmail}
                                className="text-blue-500 hover:text-blue-600 focus:outline-none"
                                style={{ background: 'none', border: 'none', padding: 0, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                click here to proceed
                            </button>.
                        </p>
                    )}

                    {/* Logout msg */}
                    {logoutMessage && <p className="text-green-500 text-center">{logoutMessage}</p>}


                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        disabled={formStatus.loading || formStatus.signupCompleted}>
                        {formStatus.loading ? 'Loading...' :
                            formStatus.signupCompleted ? 'Check your email to verify your account' :
                                (formStatus.stage === 'email' ? 'Continue' :
                                    (formStatus.stage === 'login' ? 'Login' : 'Sign Up'))}
                    </button>

                    {/* Display error message */}
                    {formStatus.error && (
                        <div className="text-red-500 text-center">
                            <p>{formStatus.error}</p>
                            {formStatus.unverifiedEmail && !formStatus.isEmailBlacklisted && (
                                <button onClick={handleResendVerification} className="text-blue-500 hover:text-blue-600">
                                    Resend Verification Email
                                </button>
                            )}
                        </div>
                    )}

                    {/* Display success message */}
                    {formStatus.success && <p className="text-green-500 text-center">{formStatus.success}</p>}
                </form>
            </div >
        </div >
    );
};

export default LoginSignup;
