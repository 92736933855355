import React, { useState, useRef, useEffect } from "react";
import { SearchIcon, BellIcon, UserCircleIcon, MenuIcon, HomeIcon, UserGroupIcon, CogIcon, MailIcon, MailOpenIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/authService";
import userService from "../../services/userService";
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from "date-fns";

const TopNavbar = ({ isProfileMenuOpen, toggleProfileMenu }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const menuRef = useRef();
  const notificationMenuRef = useRef();
  const profileIconRef = useRef();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/account", { state: { loggedOut: true } });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const timeSince = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const fetchedNotifications = await userService.getNotifications();
        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  const notificationTabs = ["Unread", "Messages", "Posts"];

  const hasUnreadNotifications = notifications.some(
    (notification) => notification.readStatus === 0
  );

  const toggleNotificationMenu = () => {
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
    if (!isNotificationMenuOpen) {
      setActiveTab("Unread");
    }
  };

  const notificationTypeToTab = {
    mention: "Posts",
    thread_reply: "Posts",
    message: "Messages",
  };

  const filteredNotifications = () => {
    let filtered = notifications;

    if (activeTab === "Unread") {
      filtered = notifications
        .filter((notification) => notification.readStatus === 0)
        .slice(0, 6);
    } else if (activeTab !== "All") {
      filtered = notifications.filter((notification) => {
        const notificationTab =
          notificationTypeToTab[notification.type.toLowerCase()];
        return notificationTab === activeTab;
      });
    }
    return filtered;
  };

  const toggleReadStatus = async (notificationId) => {
    const notificationToToggle = notifications.find(
      (notification) => notification.id === notificationId
    );

    if (notificationToToggle && notificationToToggle.readStatus === 1) {
      return;
    }

    try {
      await userService.updateNotificationStatus(notificationId, 1);
      setNotifications(
        notifications.map((notification) => {
          if (notification.id === notificationId) {
            return { ...notification, readStatus: 1 };
          }
          return notification;
        })
      );
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !profileIconRef.current.contains(event.target)
      ) {
        toggleProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleProfileMenu]);

  return (
    <nav className="sticky top-0 left-0 w-full bg-white shadow z-50 flex justify-between px-4 py-2">
      {/* Hamburger Menu for Mobile */}
      <div className="md:hidden">
        <button onClick={toggleMobileMenu}>
          <MenuIcon className="h-8 w-7" />
        </button>
      </div>

      <div className="hidden md:flex items-center">
        <Link to="/dashboard">
          <img src="/Powafull_logo.png" alt="Powafull Logo" className="h-8" />
        </Link>
      </div>

      {/* Search Bar */}
      <div className="flex-1 mx-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-500" />
          </div>
          <input
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search forums, posts, etc..."
            type="search"
            name="search"
          />
        </div>
      </div>

      {/* UserCircleIcon visible only on small screens */}
      <div className="flex items-center h-full md:hidden mr-4">
        <UserCircleIcon
          ref={profileIconRef}
          className="h-6 w-6 text-gray-700 cursor-pointer"
          onClick={() => toggleProfileMenu(!isProfileMenuOpen)}
        />
      </div>

      {/* Notification Icon */}
      <div className="relative flex items-center h-full">
        <BellIcon
          className={`h-6 w-6 text-gray-700 cursor-pointer ${hasUnreadNotifications ? "text-red-500" : ""
            }`}
          onClick={toggleNotificationMenu}
        />
        {hasUnreadNotifications && (
          <span className="absolute -top-0 -right-1 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
        )}

        {/* Notification Dropdown Menu */}
        {isNotificationMenuOpen && (
          <div
            ref={notificationMenuRef}
            className={`absolute z-50 mt-2 w-screen sm:w-[600px] bg-white border rounded-lg shadow-lg origin-top-right right-0 transition-opacity transition-transform ease-out duration-300 transform ${isNotificationMenuOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-1"
              } `}
            style={{ top: "100%", right: "-68%" }}
          >
            <div className="bg-gray-100 p-4">
              <div className="flex space-x-4">
                {notificationTabs.map((tab) => (
                  <button
                    key={tab}
                    className={`px-3 py-1 text-sm font-medium rounded-md ${activeTab === tab
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-600 hover:bg-gray-200"
                      }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>
            <ul className="divide-y divide-gray-200">
              {filteredNotifications().map((notification, index) => (
                <li
                  key={index}
                  className="px-4 py-3 hover:bg-gray-50 flex justify-between items-center"
                >
                  <div className="flex space-x-3">
                    <img
                      src={notification.avatar}
                      alt=""
                      className="h-8 w-8 rounded-full object-cover"
                    />
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {notification.message}
                      </p>
                      <p className="text-xs text-gray-500">
                        {timeSince(notification.timestamp)} ·{" "}
                        {notification.forumName}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => toggleReadStatus(notification.id)}
                    className="p-2 rounded-full text-gray-500 hover:bg-gray-200"
                  >
                    {notification.read ? (
                      <MailOpenIcon className="h-5 w-5" />
                    ) : (
                      <MailIcon className="h-5 w-5" />
                    )}
                  </button>
                </li>
              ))}
              {!filteredNotifications().length && (
                <li className="px-4 py-3 text-center text-sm text-gray-500">
                  No {activeTab.toLowerCase()} notifications.
                </li>
              )}
            </ul>
            <div className="p-4 text-center">
              <button className="px-4 py-2 bg-blue-500 text-white rounded-md">
                View all notifications
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Profile Dropdown Menu */}
      {isProfileMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 top-full mt-2 bg-white border rounded shadow-lg z-50"
        >
          <ul className="py-1 text-gray-700">
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate("/profile")}
            >
              Profile
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate("/settings")}
            >
              Account Settings
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={handleLogout}
            >
              Sign out
            </li>
          </ul>
        </div>
      )}

      {/* Mobile Menu Dropdown */}
      <div
        className={`absolute top-full left-0 z-20 w-full bg-white shadow-md transition-all duration-300 ease-in-out ${isMobileMenuOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        style={{ maxHeight: isMobileMenuOpen ? "100vh" : "0" }}
      >
        <ul className="space-y-2 p-4">
          {/* Dashboard/Home Link */}
          <li>
            <Link to="/dashboard" className="flex items-center p-2">
              <HomeIcon className="w-6 h-6" />
              <span className="ml-3">Dashboard</span>
            </Link>
          </li>
          {/* Manage Forums Link */}
          <li>
            <Link to="#" className="flex items-center p-2">
              <UserGroupIcon className="w-6 h-6" />
              <span className="ml-3">Search Forums</span>
            </Link>
          </li>
          {/* Manage Forums Link */}
          <li>
            <Link to="/my-forums" className="flex items-center p-2">
              <UserGroupIcon className="w-6 h-6" />
              <span className="ml-3">My Forums</span>
            </Link>
          </li>
          {/* Settings Link */}
          <li>
            <Link to="#" className="flex items-center p-2">
              <CogIcon className="w-6 h-6" />
              <span className="ml-3">Settings</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNavbar;
