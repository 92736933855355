import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerification = () => {
    const [verificationStatus, setVerificationStatus] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-email`, { token })
            .then(response => {
                if (response.data.alreadyVerified) {
                    setVerificationStatus("Your account is already verified! Redirecting you to the login page...");
                } else {
                    setVerificationStatus('Email successfully verified. Redirecting you to the login page...');
                }
                setTimeout(() => navigate('/account'), 10000);
            })
            .catch(error => {
                setVerificationStatus('Verification failed! Invalid/expired token or your account is already verified. Redirecting you to the login page...');
                setTimeout(() => navigate('/account'), 10000);
            });
    }, [token, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
                <div className="space-y-6 text-center">
                    <h2 className="text-lg font-semibold">Email Verification</h2>
                    <p className={`text-center ${verificationStatus.startsWith("Verification failed") ? 'text-red-500' : 'text-green-500'}`}>
                        {verificationStatus || 'Verifying...'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
