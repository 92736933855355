const memberPermissions = [
    44, // can_deletePost
    46, // can_createThread
    58, // can_editOwnPost
    59, // can_deleteOwnPost
    60, // can_createPolls
    61, // can_votePolls
    64, // can_viewPollResults
    65, // can_sendApplications
    69, // can_viewMemberList
    70, // can_sendPrivateMessages
    71, // can_viewUserProfiles
    77, // can_useAdvancedSearch
    78, // can_viewOnlineUsers
    83  // can_viewDeletedPosts
];
const moderatorPermissions = [
    ...memberPermissions,
    43, // can_editPost
    45, // can_banUser
    47, // can_deleteThread
    48, // can_editThread
    49, // can_pinThread
    53, // can_moderatePosts
    54, // can_manageMembers
    73, // can_lockTopics
    74, // can_mergeTopics
    75  // can_splitTopics
];
const administratorPermissions = [
    ...moderatorPermissions,
    50, // can_editRoles
    51, // can_assignRoles
    52, // can_viewPrivateSections
    55, // can_accessAnalytics
    56, // can_customiseForum
    57, // can_sendAnnouncements
    62, // can_editPolls
    63, // can_deletePolls
    66, // can_viewApplications
    67, // can_administrateApplications
    68, // can_manageRoles
    72, // can_manageFiles
    76, // can_manageAdvertisements
    79, // can_createSubforums
    80, // can_editSubforums
    81, // can_deleteSubforums
    82  // can_manageTags
];

const defaultRoles = [
    {
        name: "Member",
        permissions: memberPermissions,
        roleType: "default",
        immunityLevel: 1,
    },
    {
        name: "Moderator",
        permissions: moderatorPermissions,
        immunityLevel: 50,
    },
    {
        name: "Administrator",
        permissions: administratorPermissions,
        roleType: "root",
        immunityLevel: 100,
    },
];

export default defaultRoles;
