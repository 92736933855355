import React from 'react';

const PopularTopicsWidget = () => {
    return (
        <div className="p-4 border rounded shadow">
            <h3 className="text-lg font-semibold">Popular Topics</h3>
            {/* Placeholder for popular topics content */}
        </div>
    );
};

export default PopularTopicsWidget;
