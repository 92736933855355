import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import { CogIcon, TrashIcon } from "@heroicons/react/outline";
import useDocumentTitle from "../components/hooks/useDocumentTitle";
import Tippy from "@tippyjs/react";
import ConfirmationModal from "../components/common/Confirmation";
import forumService from "../services/forumService";
import { useNavigate } from "react-router-dom";

const UserForums = () => {
  useDocumentTitle("My Forums");

  const [forums, setForums] = useState([]);
  const [filteredForums, setFilteredForums] = useState([]);
  const [sortOption, setSortOption] = useState("name");
  const [forumName, setForumName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingForum, setDeletingForum] = useState({ id: null, name: "" });
  const navigate = useNavigate();

  const sortForums = useCallback(
    (forumsToSort) => {
      let sortedForums = [...forumsToSort];
      switch (sortOption) {
        case "name":
          sortedForums.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case "dateCreated":
          sortedForums.sort(
            (a, b) => new Date(b.date_created) - new Date(a.date_created)
          );
          break;
        case "totalMembers":
          sortedForums.sort((a, b) => b.total_members - a.total_members);
          break;
        case "totalPosts":
          sortedForums.sort((a, b) => b.total_posts - a.total_posts);
          break;
        default:
          break;
      }
      return sortedForums;
    },
    [sortOption]
  );

  useEffect(() => {
    const fetchForums = async () => {
      try {
        const userForums = await forumService.getUserForums();
        setForums(userForums);
      } catch (error) {
        console.error("Error fetching forums:", error);
      }
    };
    fetchForums();
  }, []);

  useEffect(() => {
    setFilteredForums(sortForums(forums));
  }, [forums, sortOption, sortForums]);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  // const handleCreateForum = async () => {
  //   if (!forumName.trim()) return;
  //   setIsCreating(true);
  //   try {
  //     const newForum = await forumService.createUserForum(forumName);
  //     const updatedForums = [...forums, newForum];
  //     setForums(updatedForums);
  //     setFilteredForums(sortForums(updatedForums));
  //     setForumName("");
  //   } catch (error) {
  //     setErrorMessage(error.response?.data?.message || "Error creating forum");
  //     console.error("Error creating forum:", error);
  //     // TODO: Handle error
  //   } finally {
  //     setIsCreating(false);
  //   }
  // };

  const MAX_FORUMS = 20;

  const handleCreateForum = async () => {
    if (!forumName.trim()) {
      alert("Please enter a valid forum name.");
      return;
    }

    if (forums.length >= MAX_FORUMS) {
      setErrorMessage(
        "You have reached your forum limit! Consider subscribing to increase your forum limit."
      );
      return;
    }

    navigate("/create-forum", { state: { forumName } });
    setForumName("");
  };

  const handleSettingsClick = (forumId) => {
    // TODO: settings page for the forum
  };

  const handleDeleteClick = (forumId, forumName) => {
    setDeletingForum({ id: forumId, name: forumName });
    setIsModalOpen(true);
  };

  const confirmDeletion = async () => {
    try {
      const response = await forumService.softDeleteForum(deletingForum.id);
      if (response.success) {
        const updatedForums = forums.map((forum) => {
          if (forum.id === deletingForum.id) {
            return { ...forum, archived: 1 };
          }
          return forum;
        });
        setForums(updatedForums);
        setFilteredForums(sortForums(updatedForums));
      } else {
        setErrorMessage(response.message || "Failed to delete forum");
      }
    } catch (error) {
      console.error("Error deleting forum:", error);
      setErrorMessage("Error deleting forum");
    }
    setIsModalOpen(false);
  };

  const [domains, setDomains] = useState([]);

  useEffect(() => {
    const fetchForums = async () => {
      try {
        const userForums = await forumService.getUserForums();
        setForums(userForums);
      } catch (error) {
        console.error("Error fetching forums:", error);
      }
    };

    const fetchDomains = async () => {
      try {
        const response = await forumService.getDomains();
        setDomains(response.domains);
      } catch (error) {
        console.error("Error fetching domains:", error);
      }
    };

    fetchForums();
    fetchDomains();
  }, []);

  const getForumUrl = (forum) => {
    const domain = domains.find(d => d.id === forum.domain_id);
    const url = domain ? `https://www.${forum.subdomain}.${domain.domain_name}` : '#';
    return url;
  };
  

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(",", " at");
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">My Forums</h1>

        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <div className="relative w-full max-w-xs mb-4 md:mb-0">
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="border border-gray-300 bg-white pl-4 pr-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition w-full"
            >
              <option value="name">Sort by Name</option>
              <option value="dateCreated">Sort by Date Created</option>
              <option value="totalMembers">Sort by Total Members</option>
              <option value="totalPosts">Sort by Total Posts</option>
            </select>
          </div>
          <p className="text-gray-500 text-xs md:text-sm italic">
            * Totals updated every 30 minutes
          </p>
        </div>

        {filteredForums.length === 0 ? (
          <div className="text-center py-10">
            <p className="text-lg text-gray-600">
              You don't own any forums yet. Start by creating one!
            </p>
          </div>
        ) : (
          filteredForums.map((forum) => (
            <div
              key={forum.id}
              className="bg-white shadow-lg rounded-lg p-5 mb-4 transition duration-300 ease-in-out hover:shadow-xl transform hover:-translate-y-1"
            >
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="w-full md:w-auto text-center md:text-left">
                  <h3 className="text-lg md:text-xl font-semibold text-gray-700">
                    {forum.name}
                    {forum.archived ? (
                      <Tippy
                        content={`This forum was deleted on ${formatDate(
                          forum.archived_date
                        )}.`}
                      >
                        <span className="text-sm text-gray-500 cursor-help">
                          {" "}
                          Deleted
                        </span>
                      </Tippy>
                    ) : forum.banned ? (
                      <Tippy content="This forum has been banned due to misuse or other violations.">
                        <span className="text-sm text-red-600 cursor-help">
                          {" "}
                          Banned
                        </span>
                      </Tippy>
                    ) : forum.draft ? (
                      <Tippy content="This forum is either in the setup process or temporarily deactivated.">
                        <span className="text-sm text-red-500 cursor-help">
                          {" "}
                          Inactive
                        </span>
                      </Tippy>
                    ) : (
                      <Tippy content="This forum is fully operational and accessible to members.">
                        <span className="text-sm text-green-500 cursor-help">
                          {" "}
                          Active
                        </span>
                      </Tippy>
                    )}
                  </h3>
                  <p className="text-xs md:text-sm text-gray-500">
                    Members: {forum.total_members} | Threads:{" "}
                    {forum.total_threads} | Posts: {forum.total_posts}
                  </p>
                  <div className="mt-2">
                  <a 
              href={getForumUrl(forum)} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-blue-500 hover:underline text-sm"
            >
              Visit {forum.name}
            </a>
              </div>
                </div>
                {!forum.banned && !forum.archived ? (
                  <div className="flex items-center mt-3 md:mt-0">
                    <button
                      onClick={() => handleSettingsClick(forum.id)}
                      className="p-2 text-blue-500 hover:text-blue-600 transition duration-200"
                    >
                      <CogIcon className="h-5 w-5 md:h-6 md:w-6" />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(forum.id, forum.name)}
                      className="p-2 text-red-500 hover:text-red-600 transition duration-200 ml-2"
                    >
                      <TrashIcon className="h-5 w-5 md:h-6 md:w-6" />
                    </button>
                  </div>
                ) : (
                  <div className="text-sm text-gray-500 italic">
                    Actions disabled ({forum.banned ? "Banned" : "Deleted"})
                  </div>
                )}
              </div>
            </div>
          ))
        )}

        {errorMessage && (
          <div className="text-center py-2 text-red-500">{errorMessage}</div>
        )}

        <div className="mt-8 flex justify-center">
          <input
            type="text"
            value={forumName}
            onChange={(e) => setForumName(e.target.value)}
            placeholder="New Forum Name"
            className="border border-gray-300 rounded-lg p-2 mr-4 focus:outline-none focus:border-blue-500 transition w-full max-w-md"
          />
          <button
            onClick={handleCreateForum}
            disabled={isCreating}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isCreating ? "Creating..." : "Create Forum"}
          </button>
        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => confirmDeletion(deletingForum.id)}
        message={`Are you sure you want to delete ${deletingForum.name}? This action cannot be undone.`}
      />
    </DashboardLayout>
  );
};

export default UserForums;
