import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginSignup from './components/Auth/LoginSignup';
import EmailVerification from './components/Auth/EmailVerification';
import PasswordReset from './components/Auth/PasswordReset';
import NotFound from './components/NotFound';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ComingSoon from './components/ComingSoon';
import DashboardPage from './pages/DashboardPage';
import UserForums from './pages/UserForums';
import CreateForum from './pages/CreateForum';
import RequireAuth from './components/RequireAuth';
import { AuthProvider } from './context/AuthContext';
import { NavbarProvider } from './context/NavbarContext';

function App() {
  return (
    <AuthProvider>
      <NavbarProvider>
        <Router>
          <Routes>
            <Route path="/account" element={<LoginSignup />} />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/reset-password/:token" element={<PasswordReset />} />
            <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/" element={<ComingSoon />} />
            {/* Protected Routes */}
            <Route path="/dashboard" element={<RequireAuth><DashboardPage /></RequireAuth>} />
            <Route path="/my-forums" element={<RequireAuth><UserForums /></RequireAuth>} />
            <Route path="/create-forum" element={<RequireAuth><CreateForum /></RequireAuth>} />
          </Routes>
        </Router>
      </NavbarProvider>
    </AuthProvider>
  );
}

export default App;
