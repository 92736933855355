import React, { createContext, useState, useCallback } from 'react';
import { checkAuthStatus } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [tokenExpiry, setTokenExpiry] = useState(null);

    const verifyAuth = useCallback(async () => {
        if (!tokenExpiry || tokenExpiry <= Date.now()) {
            const authStatus = await checkAuthStatus();
            setIsAuthenticated(authStatus.isAuthenticated);
            if (authStatus.isAuthenticated && authStatus.tokenExpiry) {
                setTokenExpiry(authStatus.tokenExpiry);
            }
        }
    }, [tokenExpiry]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, verifyAuth, tokenExpiry, setTokenExpiry }}>
            {children}
        </AuthContext.Provider>
    );
};
