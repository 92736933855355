import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const TermsOfService = () => {
    const toggleSection = (id) => {
        let section = document.getElementById(id);
        section.classList.toggle('hidden');
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-center mb-10 text-blue-600">Powafull: Terms of Service</h1>
            <div className="space-y-6">
                <section>
                    <div onClick={() => toggleSection('introduction')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">1. Introduction</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="introduction" className="mt-4">
                        <p>Welcome to Powafull. By accessing our website or using our services, you agree to be bound by these terms of service. Powafull Terms of Service was last updated on 05/01/2024 (DD/MM/YYYY).</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('useOfService')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">2. Use of Service</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="useOfService" className="mt-4">
                        <p>Powafull provides a platform for -. The service is intended for -</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('userObligations')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">3. User Obligations</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="userObligations" className="mt-4">
                        <p>As a user of Powafull, you agree not to misuse the service or help anyone else do so. You are responsible for all your activity in connection with the service.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('intellectualProperty')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">4. Intellectual Property</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="intellectualProperty" className="mt-4">
                        <p>All content on Powafull, including text, graphics, logos, and software, is the property of Powafull or its licensors and is protected by copyright and other intellectual property laws.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('disclaimerOfWarranties')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">5. Disclaimer of Warranties</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="disclaimerOfWarranties" className="mt-4">
                        <p>Powafull is provided "as is," without any warranties of any kind. We do not guarantee the accuracy or timeliness of information available from the service.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('dataSecurity')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Data Security</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="dataSecurity" className="mt-4">
                        <p>Your account's security is important. Ensure that your login credentials are kept confidential, and notify us immediately of any unauthorised use of your account.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('accountResponsibilities')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Account Responsibilities</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="accountResponsibilities" className="mt-4">
                        <p>Users are responsible for all activities that occur under their account and must abide by all applicable laws and regulations in their content creation and interactions.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('ssoUsage')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">SSO (Single Sign-On) Usage</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="ssoUsage" className="mt-4">
                        <p>When you use SSO services to log into our platform, you agree to the terms and conditions of the SSO provider. We do not receive or store your SSO provider passwords.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('limitationOfLiability')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">6. Limitation of Liability</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="limitationOfLiability" className="mt-4">
                        <p>Powafull shall not be liable for any damages that result from using or being unable to use the service.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('changesToTerms')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">7. Changes to Terms</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="changesToTerms" className="mt-4">
                        <p>We may modify these terms at any time. We will post the most current version of these terms on our website with a new effective date.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('contactInfo')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">8. Contact Information</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="contactInfo" className="mt-4">
                        <p>If you have any questions or concerns about these terms, please contact us at support@powafull.com</p>
                    </div>
                </section>

                <p>&copy; 2024 Powafull. All rights reserved.</p>
            </div>
        </div>
    );
};

export default TermsOfService;
