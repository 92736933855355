import React, { useEffect, useRef } from 'react';

const AlertModal = ({ isOpen, onClose, title, message }) => {
    const modalRef = useRef();

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.focus();
    }
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-30 overflow-y-auto h-full w-full ${isOpen ? 'flex' : 'hidden'} justify-center items-center px-4 py-6`}
      onClick={handleBackdropClick}
      ref={modalRef}
      tabIndex="-1"
    >
      <div className={`bg-white p-6 md:max-w-lg mx-auto rounded-lg shadow-xl transition ease-in-out duration-300 transform ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0'}`}>
        <h2 className="text-xl font-semibold text-gray-700 mb-4">{title}</h2>
        <p className="text-md text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-md font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
