import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const checkEmailExists = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/check-email`, { email });
        return { exists: response.data.exists };
    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('Oops! There was an error checking that email address. Please try again later.');
        } else {
            console.error('Error checking email', error);
            throw error;
        }
    }
};

const register = async (userData) => {
    try {
        const response = await axios.post(`${API_URL}/auth/register`, userData);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            throw new Error('Registration for new users is temporarily disabled. Please try again later.');
        } else if (error.response && error.response.status === 500) {
            throw new Error('Oops! There was an error during registration. Please try again later or contact us on support@powafull.com if this error persists.');
        } else {
            console.error('Error registering', error);
            throw error;
        }
    }
};

const login = async (userData) => {
    try {
        const response = await axios.post(`${API_URL}/auth/login`, userData, { timeout: 10000 });
        return response.data;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                throw new Error('The password entered is invalid!');
            } else if (error.response.status === 500) {
                throw new Error('Oops! There was an error trying to login. Please try again later or contact us on support@powafull.com if this error persists.');
            } else {
                throw new Error(error.response.data.message || 'Login failed. Please try again.');
            }
        } else {
            throw new Error('Unable to connect to the server. Please check your internet connection and try again.');
        }
    }
};

const checkAuthStatus = async () => {
    try {
        const response = await axios.get(`${API_URL}/auth/check-auth`, { withCredentials: true });
        return response.data;
    } catch (error) {
        return { isAuthenticated: false };
    }
};

const requestPasswordReset = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/request-password-reset`, { email });

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else {
            throw new Error(response.data.message || 'Error sending password reset email');
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            throw new Error('A password reset email has already been sent out. Follow the link sent to your email or try again later');
        } else if (error.response) {
            throw new Error(error.response.data.message || 'Error sending password reset email');
        } else {
            console.error('Error', error.message);
            throw new Error(error.message || 'Error sending password reset email');
        }
    }
};

const resendVerificationEmail = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/resend-verification`, { email });
        return response.data;
    } catch (error) {
        throw error;
    }
}

const logout = async () => {
    await axios.post(`${API_URL}/auth/logout`);
};

export { checkEmailExists, register, login, requestPasswordReset, resendVerificationEmail, checkAuthStatus, logout };
