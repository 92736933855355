import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Spinner from './common/Spinner';

const RequireAuth = ({ children }) => {
    const { isAuthenticated, verifyAuth, tokenExpiry } = useContext(AuthContext);

    useEffect(() => {
        if (isAuthenticated === null || tokenExpiry <= Date.now()) {
            verifyAuth();
        }
    }, [isAuthenticated, verifyAuth, tokenExpiry]);

    if (isAuthenticated === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner />
                <p className="ml-2">Hang tight, we're getting things ready for you!</p>
            </div>
        );
    }

    if (isAuthenticated === false) {
        return <Navigate to="/account" replace />;
    }

    return children;
};

export default RequireAuth;
