import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import { useNavigate, useLocation } from "react-router-dom";
import useDocumentTitle from "../components/hooks/useDocumentTitle";
import forumService from "../services/forumService";
import userService from "../services/userService";
import {
  PencilIcon,
  TrashIcon,
  ChevronDownIcon,
  XIcon,
  ArrowLeftIcon,
} from "@heroicons/react/outline";
import defaultRoles from "../utils/defaultRoles";
import Button from "../components/common/Button";
import ConfirmationModal from "../components/common/Confirmation";
import AlertModal from "../components/common/Alert";
import Tippy from "@tippyjs/react";
import { motion } from "framer-motion";
import LayoutEditor from "../components/Widgets/LayoutEditor";

const CreateForum = () => {
  useDocumentTitle("Create Forum");
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [forumName, setForumName] = useState(location.state?.forumName || "");
  const [subdomain, setSubdomain] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [forumType, setForumType] = useState("");
  const [forumTypes, setForumTypes] = useState([]);
  const [domains, setDomains] = useState([]);
  const [forumDescription, setForumDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [widgetsLayout, setWidgetsLayout] = useState([]);
  const [themeStyles, setThemeStyles] = useState({
    backgroundColor: "",
    textColor: "",
    buttonStyle: "",
  });

  const [roles, setRoles] = useState(defaultRoles);
  const [isEditing, setIsEditing] = useState(Array(roles.length).fill(false));
  const [openAccordionItemIndex, setOpenAccordionItemIndex] = useState(null);

  const [isCreating, setIsCreating] = useState(false);
  const creationStepTitles = {
    checkingSetup: "Checking your forum setup",
    creatingForum: "Creating your forum",
    assigningUser: "Assigning you to the forum",
    creatingRoles: "Creating forum roles",
    assigningRootRole: "Assigning you the root role",
    settingDefaultMemberRole: "Setting the default member role",
    completion: "Forum creation complete",
  };

  const [creationStatus, setCreationStatus] = useState({
    checkingSetup: { status: "pending", message: "" },
    creatingForum: { status: "pending", message: "" },
    assigningUser: { status: "pending", message: "" },
    creatingRoles: { status: "pending", message: "" },
    assigningRootRole: { status: "pending", message: "" },
    settingDefaultMemberRole: { status: "pending", message: "" },
    completion: { status: "pending", message: "" },
  });

  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });

  useEffect(() => {
    const fetchForumTypes = async () => {
      try {
        const response = await forumService.getForumTypes();
        setForumTypes(response.forumTypes);
      } catch (error) {
        console.error("Error fetching forum types", error);
      }
    };

    const fetchDomains = async () => {
      try {
        const response = await forumService.getDomains();
        setDomains(response.domains);
        if (response.domains.length > 0) {
          setSelectedDomain(response.domains[0].domain_name);
        }
      } catch (error) {
        console.error("Error fetching domains", error);
      }
    };

    fetchForumTypes();
    fetchDomains();
  }, []);

  const [permissions, setPermissions] = useState([]);
  const [permissionsByCategory, setPermissionsByCategory] = useState({});

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await forumService.getForumPermissions();
        setPermissions(response.permissions);
        organisePermissionsByCategory(response.permissions);
      } catch (error) {
        console.error("Error fetching permissions", error);
      }
    };

    fetchPermissions();
  }, []);

  const renderPermissionsByCategory = (role, roleIndex) => {
    return Object.entries(permissionsByCategory).map(
      ([category, permissionsInCategory]) => (
        <div key={category}>
          <h3 className="font-semibold text-lg my-2">{category}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {permissionsInCategory.map((perm) => (
              <label key={perm.id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={role.permissions.includes(perm.id)}
                  onChange={(e) =>
                    handlePermissionChange(roleIndex, perm.id, e.target.checked)
                  }
                  className="mr-2"
                  disabled={role.roleType === "root"}
                />
                <span>{perm.description}</span>
              </label>
            ))}
          </div>
        </div>
      )
    );
  };

  const organisePermissionsByCategory = (permissions) => {
    const categorisedPermissions = permissions.reduce((acc, perm) => {
      const category = perm.category || "Other";
      acc[category] = acc[category] || [];
      acc[category].push(perm);
      return acc;
    }, {});

    setPermissionsByCategory(categorisedPermissions);
  };

  const handlePermissionChange = (role, permission, isChecked) => {
    // Update role permissions logic here
  };

  useEffect(() => {
    const suggestedSubdomain = forumName
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .replace(/^-+|-+$/g, "");
    setSubdomain(suggestedSubdomain);
  }, [forumName]);

  const handleSubdomainChange = (e) => {
    const newSubdomain = e.target.value
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .replace(/^-+|-+$/g, "");
    setSubdomain(newSubdomain);
  };

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await forumService.getThemes();
        setThemes(response.themes);
      } catch (error) {
        console.error("Error fetching themes", error);
      }
    };

    fetchThemes();
  }, []);

  useEffect(() => {
    if (selectedTheme) {
      const theme = themes.find((t) => t.id.toString() === selectedTheme);
      if (theme) {
        const attributes = JSON.parse(theme.theme_attributes || "{}");
        setThemeStyles({
          background: attributes.backgroundColor || "",
          color: attributes.textColor || "",
          buttonStyle: attributes.buttonStyle || "",
          navBarStyle: attributes.navBarStyle || "",
          postStyle: attributes.postStyle || "",
        });
      }
    } else {
      setThemeStyles({
        background: "",
        color: "",
        buttonStyle: "",
        navBarStyle: "",
        postStyle: "",
      });
    }
  }, [selectedTheme, themes]);

  const handleNext = async () => {
    if (step === 1) {
      if (!forumName.trim() || !subdomain.trim()) {
        setAlert({
          isOpen: true,
          title: "Missing information",
          message: "Please enter a forum name and subdomain.",
        });
        return;
      }

      try {
        const { exists } = await forumService.checkSubdomainExists(subdomain);
        if (exists) {
          setAlert({
            isOpen: true,
            title: "Oops",
            message: `The subdomain "${subdomain}" is already taken. Please choose another one.`,
          });
          return;
        }
      } catch (error) {
        console.error("Error checking subdomain:", error);
        setAlert({
          isOpen: true,
          title: "Oops",
          message: "Error checking subdomain availability. Please try again.",
        });
        return;
      }

      setStep(step + 1);
    } else if (step === 2) {
      if (!forumType) {
        setAlert({
          isOpen: true,
          title: "Missing information",
          message: "Please select a forum type.",
        });
        return;
      }
      if (!forumDescription.trim()) {
        setAlert({
          isOpen: true,
          title: "Missing information",
          message: "Please enter a description for your forum.",
        });
        return;
      }
      setStep(step + 1);
    } else if (step === 3) {
      if (!selectedTheme) {
        setAlert({
          isOpen: true,
          title: "Missing information",
          message: "Please select a theme!",
        });
        return;
      }
      setStep(step + 1);
    } else if (step === 4) {
      setStep(step + 1);
    } else if (step === 5) {
      setStep(step + 1);
    } else if (step === 6) {
      setStep(step + 1);
      handleCreateForum();
    }
  };

  const handleCreateForum = async () => {
    setIsCreating(true);
    const selectedType = forumTypes.find((type) => type.name === forumType);
    const typeId = selectedType ? selectedType.id : null;

    const selectedDomainObj = domains.find(
      (domain) => domain.domain_name === selectedDomain
    );
    const domainId = selectedDomainObj ? selectedDomainObj.id : null;

    try {
      setCreationStatus({
        checkingSetup: "in-progress",
        creatingForum: "pending",
        assigningUser: "pending",
        creatingRoles: "pending",
        assigningRootRole: "pending",
        settingDefaultMemberRole: "pending",
        completion: "pending",
      });

      const userInfoResponse = await userService.getUserInfo();

      const userId = userInfoResponse.id;

      const date = new Date();
      const date_created = date
        .toISOString()
        .replace("T", " ")
        .substring(0, 19);

      const forumData = {
        name: forumName,
        user_owner_id: userId,
        date_created: date_created,
        subdomain: subdomain,
        domain_id: domainId,
        type_id: typeId,
        description: forumDescription,
        privacy_setting: isPrivate ? "private" : "public",
        theme_id: selectedTheme,
        draft: 1,
        roles: roles.map((role) => ({
          name: role.name,
          permissions: role.permissions,
          immunityLevel: role.immunityLevel,
          roleType: role.roleType,
        })),
        widgetsLayout: widgetsLayout.map((widget, index) => ({
          widgetType: widget.id,
          area: widget.area,
          position: index 
        })),
      };

      setCreationStatus((prevStatus) => ({
        ...prevStatus,
        checkingSetup: "completed",
        nextStep: "in-progress",
      }));

      const response = await forumService.createUserForum(forumData);

      if (response.success) {
        setCreationStatus(response.data.progress);
      } else {
        if (response.data && response.data.progress) {
          const updatedProgress = { ...response.data.progress };
          let errorFound = false;
          updatedProgress.stepsOrder.forEach((step) => {
            if (updatedProgress.stepsData[step].status === "error") {
              errorFound = true;
            }
            if (
              errorFound &&
              updatedProgress.stepsData[step].status !== "error"
            ) {
              updatedProgress.stepsData[step] = {
                status: "aborted",
                error: null,
              };
            }
          });
          setCreationStatus(updatedProgress);
        } else {
          console.error(
            "Response data is not in the expected format:",
            response
          );
        }
      }
    } catch (error) {
      console.error("Error creating forum:", error);
      setCreationStatus((prevStatus) => ({
        ...prevStatus,
        checkingSetup: "error",
      }));
      const errorStatus = { ...creationStatus, checkingSetup: "error" };
      setCreationStatus(updateStatusWithAbortion(errorStatus));
    }
  };

  const CreationStep = ({ title, status, errorMessage }) => (
    <div className="flex items-center mb-2">
      <span
        className={`mr-2 text-lg ${status === "completed"
          ? "text-green-500"
          : status === "error"
            ? "text-red-500"
            : status === "aborted"
              ? "text-gray-500"
              : "text-yellow-500"
          }`}
      >
        {status === "completed"
          ? "✅"
          : status === "error"
            ? "❌"
            : status === "aborted"
              ? "⏹️"
              : "🔄"}
      </span>
      <div>
        <p className="text-sm text-gray-700 font-bold">
          {title}: <span className="font-normal">{status}</span>
        </p>
        {status === "error" && errorMessage && (
          <p className="text-sm text-red-500">{errorMessage}</p>
        )}
      </div>
    </div>
  );

  const updateStatusWithAbortion = (creationStatus) => {
    let errorOccurred = false;
    const updatedStatus = {};

    const expectedKeys = Object.keys(creationStepTitles);

    Object.entries(creationStatus)
      .filter(([stepKey, _]) => expectedKeys.includes(stepKey))
      .forEach(([stepKey, status]) => {
        if (status === "error") {
          errorOccurred = true;
        }
        updatedStatus[stepKey] =
          errorOccurred && status !== "error" ? "aborted" : status;
      });

    return updatedStatus;
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleBackToStart = () => {
    setStep(1);
  };

  const handleCancel = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmCancel = () => {
    navigate("/my-forums");
  };

  const handleDeleteRole = (roleIndex, e) => {
    e.stopPropagation();
    const updatedRoles = roles.filter((_, index) => index !== roleIndex);
    setRoles(updatedRoles);
  };

  const handleImmunityLevelChange = (roleIndex, newLevel) => {
    const updatedRoles = roles.map((role, idx) => {
      if (idx === roleIndex) {
        return { ...role, immunityLevel: Math.max(1, Math.min(newLevel, 99)) };
      }
      return role;
    });
    setRoles(updatedRoles);
  };

  const handleRoleNameChange = (index, newName) => {
    const updatedRoles = roles.map((role, idx) => {
      if (idx === index) {
        return { ...role, name: newName };
      }
      return role;
    });
    setRoles(updatedRoles);
  };

  const handleAddRole = () => {
    if (roles.length >= 3) {
      setAlert({
        isOpen: true,
        title: "Limit reached",
        message:
          "You can only create up to 3 roles during setup. More roles can be added later in the forum settings.",
      });
      return;
    }
    setRoles([
      ...roles,
      { name: "New Role", permissions: [], immunityLevel: null },
    ]);
  };

  const toggleEdit = (index) => {
    const newIsEditing = [...isEditing];
    newIsEditing[index] = !newIsEditing[index];
    setIsEditing(newIsEditing);
  };

  const toggleAccordionItem = (index) => {
    setOpenAccordionItemIndex(openAccordionItemIndex === index ? null : index);
  };

  const isAccordionItemOpen = (index) => {
    return openAccordionItemIndex === index;
  };

  const updateWidgetsLayout = (newWidgets) => {
    console.log("Updating widgets layout in CreateForum:", newWidgets);
    setWidgetsLayout(newWidgets.map(widget => ({ id: widget.id, area: widget.area })));
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto py-4 sm:py-8">
        <div className="max-w-1xl mx-auto bg-white p-3 sm:p-5 md:p-8 rounded-lg shadow relative">
          {step > 1 && (
            <ArrowLeftIcon
              className="h-6 w-6 absolute top-5 left-5 text-gray-600 cursor-pointer"
              onClick={handleBack}
            />
          )}
          {step < 7 && (
            <XIcon
              className="h-6 w-6 absolute top-5 right-5 text-gray-600 cursor-pointer"
              onClick={handleCancel}
            />
          )}
          {step === 1 && (
            <div className="mt-6 text-center">
              <h2 className="text-xl font-semibold mb-4">
                Step 1: Forum Name & URL
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  We're thrilled that you've selected Powafull as your forum
                  platform! To begin, provide your desired forum name and forum
                  URL. If the suggested name doesn't suit your preference, feel
                  free to modify the forum URL.
                </p>
              </div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Choose Your Forum Name
              </label>
              <input
                type="text"
                value={forumName}
                onChange={(e) => setForumName(e.target.value)}
                placeholder="Forum Name"
                className="w-full p-2 border border-gray-300 rounded mb-4"
              />
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Choose Your Forum URL
                </label>
                <div className="flex flex-col md:flex-row border border-gray-300 rounded">
                  <input
                    type="text"
                    value={subdomain}
                    onChange={handleSubdomainChange}
                    placeholder="Subdomain"
                    className="p-2 flex-grow"
                  />
                  <select
                    value={selectedDomain}
                    onChange={(e) => setSelectedDomain(e.target.value)}
                    className="p-2 border-t md:border-t-0 md:border-l"
                  >
                    {domains.map((domain) => (
                      <option key={domain.id} value={domain.domain_name}>
                        {domain.domain_name}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Your forum will be accessible at{" "}
                  <span className="font-semibold">
                    {subdomain}.{selectedDomain}
                  </span>
                </p>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="mt-6 text-center">
              <h2 className="text-xl font-semibold mb-4">
                Step 2: Forum Details
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  Tell us a bit about your forum, your visiblity preference and
                  forum category. You can change these settings later in forum
                  settings if you change your mind.
                </p>
              </div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Forum Description
              </label>
              <textarea
                value={forumDescription}
                onChange={(e) => setForumDescription(e.target.value)}
                placeholder="Describe your forum - if you're a public forum, the description will be used when listing your forum"
                className="w-full p-2 border border-gray-300 rounded mb-4"
                rows="4"
              ></textarea>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Forum Visibility
                </label>
                <select
                  value={isPrivate ? "private" : "public"}
                  onChange={(e) => setIsPrivate(e.target.value === "private")}
                  className="w-full p-2 border border-gray-300 rounded mb-4"
                >
                  <option value="public">
                    Public - Any registered Powafull member can join
                  </option>
                  <option value="private">
                    Private - Invitation required to join
                  </option>
                </select>
              </div>

              <label className="block text-gray-700 text-sm font-bold mb-2">
                Forum Category
              </label>
              <select
                value={forumType}
                onChange={(e) => setForumType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
              >
                <option value="">Please select</option>
                {forumTypes.map((type) => (
                  <option key={type.id} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
              <p className="text-sm text-gray-600 mt-2 mb-6">
                If your forum category is not listed, please
                <a
                  href="mailto:support@powafull.com"
                  className="text-blue-500 hover:text-blue-600 ml-1"
                >
                  contact us{" "}
                </a>
                with the category you believe your forum should be in, and we
                will look into adding it.
              </p>
            </div>
          )}
          {step === 3 && (
            <div className="mt-6 text-center">
              <h2 className="text-xl font-semibold mb-4">
                Step 3: Choose a Theme
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  Select a theme from the dropdown to view a preview of the
                  theme. You can always change your theme later in the forum
                  settings.
                </p>
              </div>
              <select
                value={selectedTheme}
                onChange={(e) => setSelectedTheme(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
              >
                <option value="">Please select</option>
                {themes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </select>
              {selectedTheme && (
                <div className="space-y-4 p-4">
                  <div
                    style={{
                      backgroundImage: themeStyles.background,
                      height: "200px",
                    }}
                    className="rounded-lg"
                  ></div>
                </div>
              )}
            </div>
          )}

          {step === 4 && (
            <div className="mt-6 text-center">
              <h2 className="text-lg md:text-xl font-semibold mb-4">
                Step 4: Role Configuration
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  Customise your forum roles below or continue with the
                  pre-defined roles. You can always make adjustments later in
                  the forum settings.
                </p>
              </div>
              <div className="space-y-3 md:space-y-4">
                {roles.map((role, roleIndex) => (
                  <div
                    key={roleIndex}
                    className="bg-white rounded-lg shadow hover:bg-gray-50 cursor-pointer p-4"
                  >
                    <div
                      className="flex justify-between items-center mb-2"
                      onClick={() => toggleAccordionItem(roleIndex)}
                    >
                      <span className="font-bold text-base md:text-lg flex items-center">
                        {role.name}
                        <ChevronDownIcon
                          className={`ml-2 w-5 h-5 transition-transform ${isAccordionItemOpen(roleIndex)
                            ? "transform rotate-180"
                            : ""
                            }`}
                        />
                        {role.roleType === "default" && (
                          <Tippy content="Default role for new members.">
                            <span className="ml-2 px-2 py-1 text-xs font-semibold rounded bg-blue-100 text-blue-800">
                              Default
                            </span>
                          </Tippy>
                        )}
                        {role.roleType === "root" && (
                          <Tippy content="Full forum permissions - limit this role to trusted members only, as it grants significant access to the forum!">
                            <span className="ml-2 px-2 py-1 text-xs font-semibold rounded bg-red-100 text-red-800">
                              Root
                            </span>
                          </Tippy>
                        )}
                      </span>
                      <div className="flex items-center">
                        <PencilIcon
                          className="h-5 w-5 text-gray-500 mr-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isAccordionItemOpen(roleIndex)) {
                              toggleAccordionItem(roleIndex);
                            }
                            toggleEdit(roleIndex);
                          }}
                        />
                        {role.roleType !== "default" &&
                          role.roleType !== "root" && (
                            <TrashIcon
                              className="h-5 w-5 text-red-500"
                              onClick={(e) => handleDeleteRole(roleIndex, e)}
                            />
                          )}
                      </div>
                    </div>
                    {isAccordionItemOpen(roleIndex) && (
                      <div className="accordion-content mt-2">
                        {isEditing[roleIndex] && (
                          <input
                            type="text"
                            value={role.name}
                            onChange={(e) =>
                              handleRoleNameChange(roleIndex, e.target.value)
                            }
                            className="border p-2 rounded w-full mb-4"
                            autoFocus
                            onBlur={() => toggleEdit(roleIndex)}
                          />
                        )}
                        <div className="my-2">
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            Immunity Level
                          </label>
                          <Tippy content="Immunity ranges from 1-99, with 1 being the lowest and 99 the highest level of immunity.">
                            <div>
                              {role.roleType !== "default" &&
                                role.roleType !== "root" ? (
                                <input
                                  type="number"
                                  value={role.immunityLevel || ""}
                                  onChange={(e) =>
                                    handleImmunityLevelChange(
                                      roleIndex,
                                      e.target.value
                                    )
                                  }
                                  min="1"
                                  max="99"
                                  className="border p-2 rounded w-full"
                                />
                              ) : (
                                <p>{role.immunityLevel}</p>
                              )}
                            </div>
                          </Tippy>
                        </div>
                        <div className="space-y-2">
                          {renderPermissionsByCategory(role, roleIndex)}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="mt-4 flex flex-col space-y-2 md:space-y-0 md:flex-row justify-end md:space-x-2">
                {roles.length < 5 && (
                  <Button
                    onClick={handleAddRole}
                    variant="green"
                    size="md"
                    className="w-full md:w-auto"
                  >
                    Add New Role
                  </Button>
                )}
              </div>
            </div>
          )}

          {step === 5 && (
            <div className="mt-6 text-center">
              <h2 className="text-xl font-semibold mb-4">
                Step 5: Customise Forum Layout
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  Customise your forum layout - simply drag and drop widgets
                  around the layout to move their placement. You can also change
                  the layout later in your forum settings.
                </p>
              </div>
              <LayoutEditor widgets={widgetsLayout} setWidgetsLayout={updateWidgetsLayout} />
              <button onClick={() => console.log("Manual check:", widgetsLayout)}>Check Widgets Layout</button>
            </div>

          )}

          {step === 6 && (
            <div className="mt-6 px-2 md:px-4">
              <h2 className="text-xl md:text-2xl font-semibold text-center mb-4">
                Forum Summary
              </h2>

              <div className="bg-white p-4 rounded-md shadow mb-4">
                <h3 className="text-lg font-semibold">Basic Details</h3>
                <p>
                  <strong>Name:</strong> {forumName}
                </p>
                <p>
                  <strong>URL:</strong> {subdomain}.{selectedDomain}
                </p>
                <p>
                  <strong>Description:</strong> {forumDescription}
                </p>
              </div>

              <div className="bg-white p-4 rounded-md shadow mb-4">
                <h3 className="text-lg font-semibold">Specifications</h3>
                <p>
                  <strong>Category:</strong> {forumType}
                </p>
                <p className="mt-2">
                  <strong>Visibility:</strong>
                  <span
                    className={`ml-2 font-semibold ${isPrivate ? "text-red-600" : "text-green-600"
                      }`}
                  >
                    {isPrivate
                      ? "Private - Invitation required to join"
                      : "Public - Any registered Powafull member can join"}
                  </span>
                </p>
              </div>

              <div className="bg-white p-4 rounded-md shadow mb-4">
                <h3 className="text-lg font-semibold">Theme</h3>
                <p>
                  {selectedTheme
                    ? themes.find((t) => t.id.toString() === selectedTheme).name
                    : "Not selected"}
                </p>
              </div>

              <div className="bg-white p-4 rounded-md shadow mb-6">
                <h3 className="text-lg font-semibold">Roles</h3>
                <ul className="list-disc pl-5">
                  {roles.map((role, index) => (
                    <li key={index}>
                      {role.name} - Immunity Level: {role.immunityLevel}
                      {role.roleType === "default" && (
                        <Tippy content="Default role assigned to new members">
                          <span className="ml-2 px-2 py-1 text-xs font-semibold rounded bg-blue-100 text-blue-800">
                            Default
                          </span>
                        </Tippy>
                      )}
                      {role.roleType === "root" && (
                        <Tippy content="Full forum permissions - limit this role to trusted members only, as it grants significant access to the forum!">
                          <span className="ml-2 px-2 py-1 text-xs font-semibold rounded bg-red-100 text-red-800">
                            Root
                          </span>
                        </Tippy>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-center">
                <Button
                  onClick={handleNext}
                  variant="green"
                  size="md"
                  className="mb-4"
                >
                  Create Forum!
                </Button>
              </div>
            </div>
          )}

          {step === 7 && isCreating && (
            <div className="mt-6 text-center">
              <h2 className="text-xl font-semibold mb-4">
                Creating your forum...
              </h2>
              <div className="bg-gray-50 p-4 rounded-md shadow-inner mb-4">
                <p className="text-sm md:text-base text-gray-700">
                  Creating your forum may take a few moments. You can keep track
                  of the progress below.
                </p>
              </div>
              <div>
                {creationStatus.stepsOrder &&
                  creationStatus.stepsOrder.length > 0 ? (
                  creationStatus.stepsOrder.map((stepKey) => {
                    const stepData = creationStatus.stepsData[stepKey];
                    return (
                      <CreationStep
                        key={stepKey}
                        title={creationStepTitles[stepKey]}
                        status={stepData.status}
                        errorMessage={stepData.error}
                      />
                    );
                  })
                ) : (
                  <p>Loading...</p>
                )}
                {creationStatus.stepsData &&
                  creationStatus.stepsData.completion &&
                  creationStatus.stepsData.completion.status ===
                  "completed" && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                      }}
                    >
                      <p className="mt-4">
                        Click{" "}
                        <a
                          href={`/forums/${subdomain}`}
                          className="text-blue-500 hover:text-blue-600"
                        >
                          here
                        </a>{" "}
                        to visit your forum, or
                        <button
                          onClick={() => navigate("/my-forums")}
                          className="ml-2 underline"
                        >
                          go back to My Forums
                        </button>
                        .
                      </p>
                    </motion.div>
                  )}
                {creationStatus.stepsData &&
                  Object.values(creationStatus.stepsData).some(
                    (stepData) => stepData.status === "error"
                  ) && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                      }}
                    >
                      <p className="mt-4">
                        An error occurred! Correct the reported issues to create
                        your forum.
                      </p>
                      <button
                        onClick={handleBackToStart}
                        className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        Change my form fields
                      </button>
                    </motion.div>
                  )}
              </div>
            </div>
          )}

          {step < 6 && (
            <div className="mt-2 flex flex-col space-y-2 md:flex-row justify-end items-center md:space-x-2">
              <div className="flex justify-end w-full md:w-auto">
                <Button
                  onClick={handleNext}
                  variant="primary"
                  size="md"
                  className="w-full md:w-auto"
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
        <ConfirmationModal
          isOpen={showConfirmModal}
          onClose={handleCloseConfirmModal}
          onConfirm={handleConfirmCancel}
          message="Are you sure you want to cancel? Your forum will not be created and you will need to start over."
        />
        <AlertModal
          isOpen={alert.isOpen}
          title={alert.title}
          message={alert.message}
          onClose={() => setAlert({ ...alert, isOpen: false })}
        />
      </div>
    </DashboardLayout>
  );
};

export default CreateForum;
