import React, { useState } from "react";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import OnlineUsersWidget from "./OnlineUsersWidget";
import ForumStatsWidget from "./ForumStatsWidget";
import DonationBoxWidget from "./DonationBoxWidget";
import PopularTopicsWidget from "./PopularTopicsWidget";
import ChatboxWidget from "./ChatboxWidget";
import SortableItem from "./SortableItem";
import ForumLayout from "./ForumLayout";

const initialWidgets = [
  { id: "online-users", content: <OnlineUsersWidget />, area: "leftSidebar" },
  { id: "forum-stats", content: <ForumStatsWidget />, area: "belowMain" },
  { id: "donation-box", content: <DonationBoxWidget />, area: "rightSidebar" },
  { id: "popular-topics", content: <PopularTopicsWidget />, area: "belowMain" },
  { id: "chatbox", content: <ChatboxWidget />, area: "aboveMain" },
];

const LayoutEditor = ({ setWidgetsLayout }) => {
  const [widgets, setWidgets] = useState(initialWidgets);
  const [draggedItem, setDraggedItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
      onStart: (event) => {
        const item = widgets.find((widget) => widget.id === event.active.id);
        setDraggedItem(item);
      },
    }),
    useSensor(KeyboardSensor)
  );

  const determineNewArea = (dropTargetId) => {
    if (dropTargetId === "leftSidebar") {
      return "leftSidebar";
    } else if (dropTargetId === "rightSidebar") {
      return "rightSidebar";
    } else if (dropTargetId === "aboveMain") {
      return "aboveMain";
    } else if (dropTargetId === "belowMain") {
      return "belowMain";
    } else {
      return null;
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) {
      console.log("Dropped outside a droppable area");
      return;
    }

    const activeIndex = widgets.findIndex((widget) => widget.id === active.id);
    const overWidget = widgets.find((widget) => widget.id === over.id);
    const newArea = determineNewArea(over.id);

    // Prepare a copy of widgets to work with
    let updatedWidgets = [...widgets];

    // If dropped on another widget, consider the area of that widget
    const targetArea = overWidget ? overWidget.area : newArea;

    if (widgets[activeIndex].area !== targetArea) {
      // Moving to a different area
      updatedWidgets[activeIndex] = {
        ...widgets[activeIndex],
        area: targetArea,
      };
    } else if (targetArea !== "main" && overWidget) {
      // Reordering within the same non-main area
      const overIndex = widgets.findIndex((widget) => widget.id === over.id);
      updatedWidgets = arrayMove(updatedWidgets, activeIndex, overIndex);
    }

    // Update local state and propagate changes up to CreateForum
    setWidgets(updatedWidgets); // updates the state within LayoutEditor
    setWidgetsLayout(updatedWidgets); // communicates the updated state back up to CreateForum

    setDraggedItem(null);
    console.log("Widgets after update:", updatedWidgets);
  };

  const aboveMainContentWidgets = widgets.filter(
    (widget) => widget.area === "aboveMain"
  );
  const mainContentWidgets = widgets.filter((widget) => widget.area === "main");
  const belowMainContentWidgets = widgets.filter(
    (widget) => widget.area === "belowMain"
  );
  const leftSidebarWidgets = widgets.filter(
    (widget) => widget.area === "leftSidebar"
  );
  const rightSidebarWidgets = widgets.filter(
    (widget) => widget.area === "rightSidebar"
  );

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <ForumLayout
        aboveMainContentWidgets={
          <SortableContext items={aboveMainContentWidgets.map(widget => widget.id)} strategy={verticalListSortingStrategy}>
            {aboveMainContentWidgets.map(widget => (
              <SortableItem key={widget.id} id={widget.id}>
                {widget.content}
              </SortableItem>
            ))}
          </SortableContext>
        }
        mainContentWidgets={
          <SortableContext items={mainContentWidgets.map(widget => widget.id)} strategy={verticalListSortingStrategy}>
            {mainContentWidgets.map(widget => (
              <SortableItem key={widget.id} id={widget.id}>
                {widget.content}
              </SortableItem>
            ))}
          </SortableContext>
        }
        belowMainContentWidgets={
          <SortableContext items={belowMainContentWidgets.map(widget => widget.id)} strategy={verticalListSortingStrategy}>
            {belowMainContentWidgets.map(widget => (
              <SortableItem key={widget.id} id={widget.id}>
                {widget.content}
              </SortableItem>
            ))}
          </SortableContext>
        }
        leftSidebarWidgets={
          <SortableContext items={leftSidebarWidgets.map(widget => widget.id)} strategy={verticalListSortingStrategy}>
            {leftSidebarWidgets.map(widget => (
              <SortableItem key={widget.id} id={widget.id}>
                {widget.content}
              </SortableItem>
            ))}
          </SortableContext>
        }
        rightSidebarWidgets={
          <SortableContext items={rightSidebarWidgets.map(widget => widget.id)} strategy={verticalListSortingStrategy}>
            {rightSidebarWidgets.map(widget => (
              <SortableItem key={widget.id} id={widget.id}>
                {widget.content}
              </SortableItem>
            ))}
          </SortableContext>
        }
      />
      <DragOverlay>
        {draggedItem && (
          <div style={{ opacity: 0.5, zIndex: 1000, position: "fixed" }}>
            {/* Render the content of the dragged item */}
            {React.cloneElement(draggedItem.content, { style: { pointerEvents: "none" } })}
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default LayoutEditor;
