import React from 'react';
import TopNavbar from './TopNavbar';
import SideNavbar from './SideNavbar';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useNavbarContext } from '../../context/NavbarContext';

const DashboardLayout = ({ children }) => {
  const { isCollapsed, toggleNavbar } = useNavbarContext();

  return (
    <div className="flex flex-col min-h-screen h-auto bg-gray-100">
      <TopNavbar />

      <div className="flex flex-1 min-h-0">
        <SideNavbar isCollapsed={isCollapsed} />

        <div className={`transform ${isCollapsed ? 'left-20' : 'left-64'} -translate-x-full flex items-center`}>
          <button onClick={toggleNavbar} className="focus:outline-none">
            {isCollapsed ? <ChevronRightIcon className="h-5 w-5" /> : <ChevronLeftIcon className="h-5 w-5" />}
          </button>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 overflow-y-auto p-3 sm:p-5 md:pb-5">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
