const Button = ({ children, onClick, className, type = "button", disabled = false, variant = "primary", size = "md", ariaLabel }) => {
    const baseStyle = "rounded-lg font-semibold transition-all ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2";
    const sizeStyles = {
        sm: "px-2 py-1 text-xs",
        md: "px-4 py-2 text-sm",
        lg: "px-6 py-3 text-md",
    };
    const variantStyles = {
        primary: "bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-300",
        secondary: "bg-gray-500 hover:bg-gray-600 text-white focus:ring-gray-300",
        danger: "bg-red-500 hover:bg-red-600 text-white focus:ring-red-300",
        light: "bg-gray-200 hover:bg-gray-300 text-gray-700 focus:ring-gray-200",
        green: "bg-green-600 hover:bg-green-700 text-white focus:ring-green-300",
    };
    const disabledStyle = disabled ? "opacity-50 cursor-not-allowed" : "";

    return (
        <button
            type={type}
            onClick={onClick}
            className={`${baseStyle} ${sizeStyles[size]} ${variantStyles[variant]} ${disabledStyle} ${className}`}
            disabled={disabled}
            aria-label={ariaLabel || children}
        >
            {children}
        </button>
    );
};

export default Button;
