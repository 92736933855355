import { useEffect } from 'react';

const useDocumentTitle = (title) => {
    useEffect(() => {
        if (title) {
            document.title = `Powafull - ${title}`;
        } else {
            document.title = 'Powafull';
        }
    }, [title]);
};

export default useDocumentTitle;