import React from 'react';

const NotFound = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="text-center">
      <h1 className="text-xl font-bold">404 - Page Not Found</h1>
      <p className="text-lg mt-4">Sorry, the page you are looking for does not exist.</p>
      <p className="text-md mt-4">Redirecting you to the homepage...</p>
    </div>
  </div>
);

export default NotFound;