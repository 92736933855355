import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const PrivacyPolicy = () => {
    const toggleSection = (id) => {
        let section = document.getElementById(id);
        section.classList.toggle('hidden');
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold text-center mb-10 text-blue-600">Powafull: Privacy Policy</h1>
            <div className="space-y-6">
                <section>
                    <div onClick={() => toggleSection('introduction')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Introduction</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="introduction" className="mt-4">
                        <p>Welcome to Powafull. We are committed to respecting the privacy of our users and protecting their information. 
                            This Privacy Policy outlines the types of personal information we collect, how it's used, and the measures we take to safeguard it.
                            This Privacy Policy was last updated on 05/01/2024 (DD/MM/YYYY).</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('collectionUse')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Information Collection and Use</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="collectionUse" className="mt-4">
                        <p>Our platform is designed for -. To provide our services, we collect the following data:</p>
                        <ul>
                            <li>-</li>
                            <li>-</li>
                        </ul>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('collectionUse')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Information Collection and Use</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="collectionUse" className="mt-4">
                        <p>This information is used to:</p>
                        <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>- Communicate important updates and offers (you can opt in/out of offers).</li>
                        </ul>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('dataProtection')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Data Protection</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="dataProtection" className="mt-4">
                        <p>We implement a variety of security measures to maintain the safety of your personal information.
                            Your personal information is stored in secure networks and is only accessible by a limited number of persons with special access rights who are required to keep the information confidential.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('thirdPartyDisclosure')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Third-Party Disclosure</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="thirdPartyDisclosure" className="mt-4">
                        <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties unless we provide users with advance notice.
                            This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users,
                            so long as those parties agree to keep this information confidential.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('userRights')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">User Rights</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="userRights" className="mt-4">
                        <p>You have the right to access, correct, or delete your personal information held by us under General Data Protection Regulation (GDPR). Please contact us if you wish to exercise these rights.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('yourConsent')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Your Consent</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="yourConsent" className="mt-4">
                        <p>By using our platform, you consent to our Privacy Policy.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('policyChanges')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Changes to our Privacy Policy</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="policyChanges" className="mt-4">
                        <p>If we decide to change our privacy policy, we will update the Privacy Policy modification date above.</p>
                    </div>
                </section>

                <section>
                    <div onClick={() => toggleSection('contactUs')} className="flex justify-between items-center cursor-pointer">
                        <h2 className="text-2xl font-semibold">Contacting Us</h2>
                        <ChevronDownIcon className="h-6 w-6" />
                    </div>
                    <div id="contactUs" className="mt-4">
                        <p>If you have any questions regarding this privacy policy, you may contact us using the information below:</p>
                        <p>support@powafull.com</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
