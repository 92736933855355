import React from 'react';
import { useDroppable } from '@dnd-kit/core';

function DroppableContainer({ id, children, className }) {
    const { setNodeRef } = useDroppable({ id });
    return <div ref={setNodeRef} className={`droppable-area ${className || ''}`}>{children}</div>;
}

const ForumLayout = ({ aboveMainContentWidgets, mainContentWidgets, belowMainContentWidgets, leftSidebarWidgets, rightSidebarWidgets }) => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
          <div className="bg-indigo-600 p-2 text-white text-center">Forum Banner</div>
          <nav className="bg-gray-800 text-white p-3 text-center">Forum Header (Links)</nav>
          <div className="flex-grow flex">
            <DroppableContainer id="leftSidebar" className="flex-none w-1/6">
              <h2 className="text-xl font-bold text-gray-700 bg-gray-200 p-2 mb-4">Left Sidebar</h2>
              <aside className="bg-white p-4 mr-4 h-full">
                {leftSidebarWidgets}
              </aside>
            </DroppableContainer>
    
            <div className="flex-1">
              <DroppableContainer id="aboveMain" className="mb-4">
                <h2 className="text-xl font-bold text-gray-700 bg-gray-200 p-2 mb-4">Above Main Content</h2>
                {aboveMainContentWidgets}
              </DroppableContainer>
    
              <main className="bg-gray-200 p-4 rounded min-h-[500px] mt-4">
                <h2 className="text-xl font-bold text-gray-700 bg-gray-200 p-2 mb-4">Main Content</h2>
                <p>You cannot drop widgets into the main content area</p>
                {mainContentWidgets}
              </main>
    
              <DroppableContainer id="belowMain" className="mt-4">
                <h2 className="text-xl font-bold text-gray-700 bg-gray-200 p-2 mb-4">Below Main Content</h2>
                {belowMainContentWidgets}
              </DroppableContainer>
            </div>
    
            <DroppableContainer id="rightSidebar" className="flex-none w-1/6">
              <h2 className="text-xl font-bold text-gray-700 bg-gray-200 p-2 mb-4">Right Sidebar</h2>
              <aside className="bg-white p-4 ml-4 h-full">
                {rightSidebarWidgets}
              </aside>
            </DroppableContainer>
          </div>
          <footer className="bg-gray-800 text-white p-3 text-center">Forum Footer</footer>
        </div>
      );
    };  

export default ForumLayout;
