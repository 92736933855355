import React, { useEffect } from 'react';
import useDocumentTitle from '../components/hooks/useDocumentTitle';
import DashboardLayout from '../components/Dashboard/DashboardLayout';

const DashboardPage = () => {
useDocumentTitle('Dashboard');
  
  // Scrolls the user to the top of the dashboard page
  useEffect(() => {
  window.scrollTo(0, 0);
  }, []);
  
  return (
    <DashboardLayout>
      <h1 className="text-xl font-semibold text-gray-700">Dashboard</h1>
      <p>Welcome to your dashboard. This is where you can manage your forums, view your profile, and explore other features.</p>
    </DashboardLayout>
  );
};

export default DashboardPage;
