import React from 'react';

const OnlineUsersWidget = () => {
    return (
        <div className="p-4 border rounded shadow">
            <h3 className="text-lg font-semibold">Online Users</h3>
        </div>
    );
};

export default OnlineUsersWidget;
