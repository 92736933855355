import React from 'react';
import { HomeIcon, UserGroupIcon, CogIcon } from '@heroicons/react/outline';
import ProfileEditor from './ProfileEditor';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';
import { useNavbarContext } from '../../context/NavbarContext';

const SideNavbar = () => {
  const { isCollapsed } = useNavbarContext();
  const sidebarWidth = isCollapsed ? 'w-18' : 'w-60';

  return (
    <aside className={`${sidebarWidth} hidden md:flex flex-col transition-all duration-300`} aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800 flex-grow">
        <ul className="space-y-2">
          {/* Dashboard/Home Link */}
          <li>
            <Tippy content="Dashboard" placement="right" disabled={!isCollapsed}>
            <Link to="/dashboard" className="flex items-center p-2">
                <HomeIcon className="w-6 h-6" />
                {!isCollapsed && <span className="ml-3">Dashboard</span>}
              </Link>
            </Tippy>
          </li>
          {/* Search Forums Link */}
          <li>
            <Tippy content="Search Forums" placement="right" disabled={!isCollapsed}>
              <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <UserGroupIcon className="w-6 h-6" />
                {!isCollapsed && <span className="ml-3">Search Forums</span>}
              </a>
            </Tippy>
          </li>
          {/* My Forums Link */}
          <li>
            <Tippy content="My Forums" placement="right" disabled={!isCollapsed}>
              <Link to="/my-forums" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <UserGroupIcon className="w-6 h-6" />
                {!isCollapsed && <span className="ml-3">My Forums</span>}
              </Link>
            </Tippy>
          </li>
          {/* Settings Link */}
          <li>
            <Tippy content="Settings" placement="right" disabled={!isCollapsed}>
              <a href="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <CogIcon className="w-6 h-6" />
                {!isCollapsed && <span className="ml-3">Settings</span>}
              </a>
            </Tippy>
          </li>
        </ul>
      </div>

      {/* Profile Editor at bottom */}
      <div className="py-0 px-2 bg-gray-50 rounded dark:bg-gray-800">
        <ProfileEditor collapsed={isCollapsed} />
      </div>
    </aside>
  );
};

export default SideNavbar;