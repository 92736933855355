import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LockClosedIcon } from '@heroicons/react/outline';

const PasswordReset = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handlePasswordReset = async () => {
        if (!newPassword) {
            setMessage("Please enter a new password.");
            return;
        }
        setIsSubmitting(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, {
                token,
                newPassword
            });

            setMessage(response.data.message);
            setTimeout(() => navigate('/account'), 5000);
        } catch (error) {
            setMessage(error.response?.data?.message || "An error occurred while resetting the password.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
                <div className="space-y-6">
                    <h2 className="text-lg font-semibold text-center">Reset Your Password</h2>
                    <div className="flex border rounded-md focus-within:ring-2 focus-within:ring-blue-500">
                        <LockClosedIcon className="w-6 h-6 m-2 text-gray-500" />
                        <input
                            type="password"
                            className="w-full px-4 py-2 focus:outline-none"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            required
                        />
                    </div>
                    <button
                        onClick={handlePasswordReset}
                        className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Resetting...' : 'Reset Password'}
                    </button>
                    {message && (
                        <p className={`text-center ${message.startsWith("An error") ? 'text-red-500' : 'text-green-500'}`}>{message}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
