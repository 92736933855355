import React, { useState, useRef, useEffect } from 'react';
import { UserCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import userService from '../../services/userService';
import { logout } from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const ProfileEditor = ({ collapsed }) => {
    const [username, setUsername] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef();
    let chevronClicked = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await userService.getUserInfo();
                setUsername(userInfo.username);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, []);

    const toggleMenu = (event) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
        chevronClicked.current = true;
    };

    const getSubmenuPosition = () => {
        if (collapsed) {
            return "left-0";
        }
        return "right-0";
    };

    const getMenuWidth = () => {
        if (collapsed) {
            return "w-40";
        }
        return "w-40";
    };

    const handleClickOutside = (event) => {
        if (chevronClicked.current) {
            chevronClicked.current = false;
            return;
        }

        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    const handleProfileClick = (event) => {
        if (collapsed) {
            toggleMenu(event);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/account', { state: { loggedOut: true } });
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`relative flex items-center justify-between p-2 mb-5 bg-white rounded-lg shadow-xs dark:bg-gray-800`}>
            <div className="flex items-center">
                <UserCircleIcon
                    className="w-8 h-8 rounded-full cursor-pointer"
                    onClick={handleProfileClick}
                />
                {!collapsed && (
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-300">{username || "Username"}</p>
                        <p className="text-xs text-gray-600 dark:text-gray-400 cursor-pointer" onClick={() => navigate(`/profile/${username}`)}>View profile</p>
                    </div>
                )}
            </div>
            {!collapsed && (
                <div>
                    {menuOpen ? (
                        <ChevronUpIcon className="w-5 h-5 text-gray-600 dark:text-gray-400 cursor-pointer" onClick={toggleMenu} />
                    ) : (
                        <ChevronDownIcon className="w-5 h-5 text-gray-600 dark:text-gray-400 cursor-pointer" onClick={toggleMenu} />
                    )}
                </div>
            )}
            <div
                ref={menuRef}
                className={`absolute ${getSubmenuPosition()} bottom-full mb-2 transition-opacity ease-out duration-300 bg-white border rounded shadow-lg ${menuOpen ? 'opacity-100 visible ' + getMenuWidth() : 'opacity-0 hidden'
                    }`}>
                <ul className="py-1 text-gray-700">
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => navigate('/profile')}>Profile</li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => navigate('/settings')}>Account Settings</li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={handleLogout}>Sign out</li>
                </ul>
            </div>
        </div>
    );
};

export default ProfileEditor;
