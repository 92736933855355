import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_BASE_URL;

class userService {
    async getUserInfo() {
        try {
            const response = await axios.get(`${API_URL}/user/info`);
            return response.data.user;
        } catch (error) {
            console.error('Error fetching user info', error);
            throw error;
        }
    }
    async getNotifications() {
        try {
            const response = await axios.get(`${API_URL}/user/notifications`);
            return response.data;
        } catch (error) {
            console.error('Error fetching notifications', error);
            throw error;
        }
    }
    async updateNotificationStatus(notificationId, readStatus) {
        try {
            const response = await axios.post(`${API_URL}/user/updateNotificationStatus`, { notificationId, readStatus });
            return response.data;
        } catch (error) {
            console.error('Error updating notification status', error);
            throw error;
        }
    }
};

export default new userService();
