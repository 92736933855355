import React from 'react';
import useDocumentTitle from './hooks/useDocumentTitle';
import { useSpring, animated } from 'react-spring';
import { FaTwitter, FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import backgroundImage from '../images/bg.jpg';

const ComingSoon = () => {
  useDocumentTitle('Coming Soon');

  const contentFade = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: 500 });

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen p-4 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <animated.div style={contentFade} className="relative z-10 text-white text-center">
        <h1 className="text-4xl md:text-6xl font-bold">Powafull</h1>
        <h2 className="text-2xl md:text-3xl font-bold mt-4">Coming Soon</h2>
        <p className="mt-4 text-lg md:text-xl">Powafull is gearing up to transform the world of forum creation with cutting-edge technologies.</p>
        <div className="flex justify-center space-x-4 mt-8">
          <a href="https://twitter.com/PowafullApp" className="hover:text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"><FaTwitter size={24} /></a>
          <a href="https://facebook.com/PowafullApp" className="hover:text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"><FaFacebookF size={24} /></a>
          <a href="https://instagram.com/PowafullApp" className="hover:text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"><FaInstagram size={24} /></a>
          <a href="https://tiktok.com/@PowafullApp" className="hover:text-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"><FaTiktok size={24} /></a>
        </div>
      </animated.div>
    </div>
  );
};

export default ComingSoon;
