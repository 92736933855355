import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_BASE_URL;

class forumService {
  async getUserForums() {
    try {
      const response = await axios.get(`${API_URL}/forum/forums`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user forums', error);
      throw error;
    }
  }
  async createUserForum(forumData) {
    try {
      const response = await axios.post(`${API_URL}/forum/create`, forumData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Detailed Error creating forum', error.response || error);

      return {
        success: false,
        message: error.response?.data?.message || 'Unknown error occurred',
        data: error.response?.data 
      };
    }
}
  async softDeleteForum(forumId) {
    try {
      const response = await axios.post(`${API_URL}/forum/softDelete`, { forumId });
      return response.data;
    } catch (error) {
      console.error('Error soft deleting forum', error);
      throw error;
    }
  }

  async getForumTypes() {
    try {
      const response = await axios.get(`${API_URL}/forum/forumTypes`);
      return response.data;
    } catch (error) {
      console.error('Error fetching forum types', error);
      throw error;
    }
  }

  async getDomains() {
    try {
      const response = await axios.get(`${API_URL}/forum/domains`);
      return response.data;
    } catch (error) {
      console.error('Error fetching domains', error);
      throw error;
    }
  }
  async getThemes() {
    try {
      const response = await axios.get(`${API_URL}/forum/themes`);
      return response.data;
    } catch (error) {
      console.error('Error fetching themes', error);
      throw error;
    }
  }
  async getForumPermissions() {
    try {
      const response = await axios.get(`${API_URL}/forum/permissions`);
      return response.data;
    } catch (error) {
      console.error('Error fetching forum permissions', error);
      throw error;
    }
  }
  async checkSubdomainExists(subdomain) {
    try {
      const response = await axios.get(`${API_URL}/forum/checkSubdomain`, { params: { subdomain } });
      return response.data;
    } catch (error) {
      console.error('Error checking subdomain', error);
      throw error;
    }
  }
};

export default new forumService();
